import Axios, { AxiosResponse } from 'axios'
import Quill from 'quill'
import UnsavedChanges from 'src/unsaved_changes'
import { ActionEvent } from 'stimulus'
import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['input', 'lastSavedAt', 'loader']

  static values = {
    autoSaveUrl: String
  }

  declare readonly inputTarget?: HTMLTextAreaElement
  declare readonly lastSavedAtTarget?: HTMLSpanElement
  declare readonly loaderTarget?: HTMLSpanElement

  declare readonly autoSaveUrlValue?: string

  editor: Quill
  autoSaveInterval: NodeJS.Timer
  unsavedChanges: boolean
  changedHandler: () => void

  connect() {
    const toolbar = [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      ['clean'],
      [{'align': []}]
    ]

    this.unsavedChanges = false
    this.editor = new Quill('.quill-editor', {
      theme: 'snow',
      modules: {
        toolbar: toolbar
      }
    })

    this.autoSaveInterval = setInterval(() => {
      this.autoSave()
    }, 10 * 1000)

    this.editor.on("text-change", this.changedHandler = () => { this.unsavedChanges = true })

  }

  disconnect(): void {
    this.editor.off("text-change", this.changedHandler)
    clearInterval(this.autoSaveInterval)
  }

  submit(event: ActionEvent) {
    this.inputTarget.value = this.editor.root.innerHTML
  }

  private async autoSave() {
    if (this.autoSaveUrlValue) {
      try {
        if (this.unsavedChanges) {
          this.loaderTarget.classList.remove("hidden")
        }

        const result: AxiosResponse<any, any> = await Axios.patch(this.autoSaveUrlValue, {
          answer: {
            testimonial: this.editor.root.innerHTML
          }
        })

        this.unsavedChanges = false
        this.lastSavedAtTarget.innerHTML = result.data.last_updated_at
        this.loaderTarget.classList.add("hidden")
      } catch (error) {
        console.error(error)
      }
    }
  }

}