export default function setSrcObject(stream: MediaStream | null, element: any): void {
  if ('srcObject' in element) {
      element.srcObject = stream;
  } else if ('mozSrcObject' in element) {
      element.mozSrcObject = stream;
  } else {
      element.srcObject = stream;
  }
}

export function calculateTimeDuration(secs: number) {
  let hr = Math.floor(secs / 3600);
  let min = Math.floor((secs - (hr * 3600)) / 60);
  let sec = Math.floor(secs - (hr * 3600) - (min * 60));
  let minPadded: string = `${min}`
  let secPadded: string = `${sec}`

  if (min < 10) {
    minPadded = `0${min}`;
  }

  if (sec < 10) {
    secPadded = `0${sec}`;
  }

  if(hr <= 0) {
    return `${minPadded}:${secPadded}`;
  }

  return `${hr}:${minPadded}:${secPadded}`;
}