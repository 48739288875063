import Axios from "axios"; /* Todo: find another tech to manage upload progress, drop axios, try with ActiveStorage direct upload? */
import { uploaderProps } from "types/recorder";

export default class Uploader {

  progressBarSelector?: string

  constructor(progressBarSelector?: string) {
    this.progressBarSelector = progressBarSelector
  }

  upload({ file, url }: uploaderProps): any {

    return Axios
    .put(url, file, {
      onUploadProgress: (evt: any) => this.onUploadProgress(evt)
    })
  }

  private onUploadProgress(progressEvent: any) {
    if (this.progressBarSelector) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

      const progressBar = document.querySelector(this.progressBarSelector) as HTMLElement

      if (progressBar) {
        const progressBarFill = progressBar.querySelector(`${this.progressBarSelector}__fill`) as HTMLElement

        progressBar.classList.remove("hidden")

        if (percentCompleted < 100 ) {
          progressBarFill.style.width = `${percentCompleted}%`
        } else {
          progressBarFill.style.width = `0%`
          progressBar.classList.add("hidden")
        }
      }

    }
  }
}
