import tippy from "tippy.js"
import { Instance } from "tippy.js/index"

let tippyInstances: Instance[] = []

function startTippy() {
  if (tippyInstances.length > 0) {
    tippyInstances.forEach((instance: Instance) => {
      instance.destroy()
    })
  }

  tippyInstances = tippy('[data-tippy-content]', { placement: 'bottom', theme: 'blue' });
}

document.addEventListener("turbo:load", startTippy)
document.addEventListener("turbo:frame-load", startTippy)
