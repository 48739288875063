import { ActionEvent } from 'stimulus'
import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {

  static targets = ["checkbox"]

  declare readonly checkboxTargets?: HTMLInputElement[]

  connect() {
  }

  acceptDocument(evt: CustomEvent) {
    const inputContainer: HTMLElement = this.checkboxTargets.find(checkbox => {
      return checkbox.dataset.id === evt.detail.accepted
    })
    const input: HTMLInputElement = inputContainer.querySelector("input[type='checkbox']") as HTMLInputElement
    inputContainer.classList.remove("inline-checkbox--pending")
    inputContainer.classList.add("inline-checkbox--accepted")
    input.checked = true
  }

  openDocument(evt: ActionEvent): boolean {
    evt.preventDefault()
    evt.stopPropagation()
    evt.stopImmediatePropagation()
    const target = evt.target as HTMLDivElement

    return false
  }
}
