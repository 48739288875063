import { ActionEvent } from 'stimulus'
import { ApplicationController } from 'stimulus-use'
import { FrameElement } from '@hotwired/turbo/dist/types/elements/frame_element'

export default class extends ApplicationController {

  declare modal: HTMLElement

  connect() {
    this.modal = document.querySelector(`.${this.element.className}`)

    /* Get z-index of last opened modal, change new modal z-index to be on top */
    let lastZIndex = 0

    document.querySelectorAll(".modal").forEach((modal: HTMLDivElement) => {
      const modalIndex = parseInt(modal.style.zIndex)
      if (modalIndex > lastZIndex) {
        lastZIndex = modalIndex
      }
    })

    this.modal.style.zIndex = `${lastZIndex + 10}`
  }

  disconnect() {
  }

  accept(event: ActionEvent) {
    this.dispatch("accepted", { accepted: event.params.accepted })
    this.close()
  }

  close() {
    // Add class to start close animation
    this.element.classList.add("modal-closed")

    // document.querySelector("#main").classList.remove("hidden")
    // document.querySelector(".loader-wrapper").classList.add("hidden")

    setTimeout(() => {
      // Remove src reference from parent frame element
      // Without this, turbo won't re-open the modal on subsequent clicks
      const turboFrame:FrameElement = this.element.parentNode as FrameElement
      turboFrame.src = undefined

      // Remove the modal element after the fade out so it doesn't blanket the screen
      this.element.remove()
    }, 300);
  }

  handleKeyup(e: KeyboardEvent) {
    if (e.code == 'Escape') {
      this.close()
    }
  }
}
