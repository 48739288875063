import { ExtendedWindow } from "types/extended_window"

import { Application } from "@hotwired/stimulus"

declare let window: ExtendedWindow
const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
