import Dropzone from "dropzone";

import { FrameElement } from '@hotwired/turbo/dist/types'

import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['documents']

  static values = {
    zone: String,
    url: String
  }

  declare readonly documentsTarget?: FrameElement
  declare zoneValue: string
  declare urlValue: string

  connect() {
    let myDropzone = new Dropzone(this.zoneValue, { url: this.urlValue });

    myDropzone.on("success", (file) => {
      this.documentsTarget.reload()

      myDropzone.removeFile(file);
    });
  }
}