import { ApplicationController } from 'stimulus-use'

import { ActionEvent } from "@hotwired/stimulus"

export default class extends ApplicationController {
  static targets = ['player']

  static values = {
    downloadable: Boolean
  }

  declare readonly playerTarget: HTMLVideoElement
  declare downloadableValue: boolean

  disableContextMenu(evt: PointerEvent) {
    evt.preventDefault()
    return false
  }

  connect() {
    if (!this.downloadableValue) {
      this.playerTarget.addEventListener('contextmenu', this.disableContextMenu)
    }
  }

  disconnect() {
    if (!this.downloadableValue) {
      this.playerTarget.removeEventListener('contextmenu', this.disableContextMenu)
    }
  }
}
