import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {

  autoHide: number

  connect() {
    this.autoHide = window.setInterval(() => {
      this.close()
    }, 5000);
  }

  disconnect() {
    clearInterval(this.autoHide);
  }

  close() {
    this.element.remove()
  }
}
