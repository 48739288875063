import { ApplicationController } from 'stimulus-use'

import { ActionEvent } from "@hotwired/stimulus"

export default class extends ApplicationController {
  static targets = ['player']

  declare readonly playerTarget: HTMLVideoElement

  connect() {}

  disconnect() {}

  switch(evt: ActionEvent) {
    this.playerTarget.src = evt.params.url
    this.playerTarget.poster = evt.params.poster
  }
}
