import { ActionEvent } from 'stimulus';
import Dropdown from 'stimulus-dropdown'
import { useTransition } from 'stimulus-use';

export default class extends Dropdown  {

  connect() {
    useTransition(this as any, {
      element: this.menuTarget,
      enterActive: 'fade-enter-active',
      enterFrom: 'fade-enter-from',
      enterTo: 'fade-enter-to',
      leaveActive: 'fade-leave-active',
      leaveFrom: 'fade-leave-from',
      leaveTo: 'fade-leave-to',
      hiddenClass: 'hidden',
      // set this, because the item *starts* in an open state
      transitioned: false,
    });
  }

  changeSurvey(event: ActionEvent) {
    event.preventDefault()
    window.location = event.params.url
  }
}
