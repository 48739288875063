// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import * as ActiveStorage from "@rails/activestorage"
import { Turbo } from "@hotwired/turbo-rails"

import Iconify from '@iconify/iconify'
import adapter from 'webrtc-adapter'

//import "src/jquery"
import "src/load_js_modules"

import "./controllers"

ActiveStorage.start()

Iconify.getVersion() // needed to properly include Iconify in builds
console.debug(adapter.browserDetails)

Turbo.setProgressBarDelay(0)