import { ActionEvent } from '@hotwired/stimulus'
import RecordRTC, { RecordRTCPromisesHandler } from 'recordrtc'
import setSrcObject, { calculateTimeDuration } from 'src/recorder'

import { ApplicationController } from 'stimulus-use'

/* Used tuto: https://ourcodeworld.com/articles/read/671/how-to-record-a-video-with-audio-in-the-browser-with-javascript-webrtc */

export default class extends ApplicationController {
  static targets = ['player', 'startButton', 'eltToHide', 'chosenFile', 'duration', 'noVideo']

  static values = {
    state: String,
    audioOnly: Boolean
  }

  /*
    states are:
      new: initial state when recording or uploading a video for the first time
      recorded: a video has been recorded
      device-ready: the webcam is ready to be used for recording
      recording: user is currently recording a video
  */

  declare readonly chosenFileTarget?: HTMLElement
  declare readonly startButtonTarget?: HTMLElement
  declare readonly eltToHideTargets?: HTMLElement[]
  declare readonly playerTarget?: HTMLVideoElement
  declare readonly durationTarget?: HTMLDivElement
  declare readonly noVideoTarget?: HTMLDivElement
  declare readonly hasNoVideoTarget: boolean

  declare stateValue: string
  declare audioOnlyValue: boolean

  recordStartedAt?: number
  refreshInterval?: NodeJS.Timer
  previousDuration?: number // used when pausing record, to save previous duration calculation

  recorder?: RecordRTCPromisesHandler
  stream?: MediaStream
  pause?: boolean

  connect() {
    this.pause = false
  }

  disconnect() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval)
    }
  }

  /* start:
    When a clic on the main "start" button happens, behavior changes according to current recorder state */
  start(evt: ActionEvent) {
    evt.preventDefault()

    switch (this.stateValue) {
      case 'new':
      case 'recorded':
        this.getDevice()
        break;
      case 'device-ready':
        this.startRecording()
        break;
      case 'recording':
        this.stopRecording()
        break;
      default:
        console.debug("Unknown state")
        break;
    }
  }

  /* upload:
    Handle file input selection */
  upload(evt: ActionEvent) {
    const fileInput: HTMLInputElement = evt.currentTarget as HTMLInputElement
    const file: File = fileInput.files[0]

    this.displayBlob(file)
    this.displayChosenFile(file.name)
    this.toggleRecordButtons("show")
  }

  /* togglePause:
    Allow the user to pause / resume his recording */
  togglePause(evt: ActionEvent) {
    evt.preventDefault()

    /* start or stop recording time tracking */
    this.toggleDurationTracking()

    if (this.pause) {
      this.recorder.resumeRecording()
      this.element.querySelector(`.play-button`).classList.add('hidden')
      this.element.querySelector(`.pause-button`).classList.remove('hidden')
      this.pause = false
    } else {
      this.recorder.pauseRecording()
      this.element.querySelector(`.pause-button`).classList.add('hidden')
      this.element.querySelector(`.play-button`).classList.remove('hidden')
      this.pause = true
    }
  }

  /* refreshDuration:
    refresh recording duration */
  private refreshDuration(duration?: number) {
    if (duration === undefined) {
      duration = (new Date().getTime() - this.recordStartedAt) / 1000
    }

    this.durationTarget.querySelector('span').innerHTML = `${calculateTimeDuration(duration + this.previousDuration)}`
  }

  /* toggleDurationTracking
    start or stop the interval to track recording duration */
  private toggleDurationTracking() {
    if(this.refreshInterval) {
      clearInterval(this.refreshInterval)
      this.refreshInterval = undefined
      /* save previous recorded duration, to handle record pauses */
      this.previousDuration = (new Date().getTime() - this.recordStartedAt) / 1000
    } else {
      this.recordStartedAt = new Date().getTime()

      this.refreshInterval = setInterval(() => {
        this.refreshDuration()
      }, 1000)
    }
  }

  private toggleRecordButtons(state: string = "hidden") {
    if (state === "hidden") {
      this.eltToHideTargets.forEach((elt) => elt.classList.add("hidden"))
    } else {
      this.eltToHideTargets.forEach((elt) => elt.classList.remove("hidden"))
    }
  }

  private hideNoVideoOverlay() {
    if (this.hasNoVideoTarget) {
      this.noVideoTarget.classList.add("hidden")
    }
  }

  /*
    stateValueChanged:
    A stimulus method, handling changes of state value
  */
  private stateValueChanged(state: string, previousState?: string) {
    /* little trick to handle which buttons should be displayed according to current state, thanks to CSS classes
        also hide the buttons of the previous state */
    if (previousState) {
      this.element.querySelectorAll(`.${previousState}`).forEach((elt) => elt.classList.add('hidden'))
    }

    this.element.querySelectorAll(`.${state}`).forEach((elt) => elt.classList.remove('hidden'))

    switch (state) {
      case "recording":
        /* when starting a new recording, reset duration to 0, hide unrequired buttons and start duration tracking */
        this.previousDuration = 0
        this.refreshDuration(0)

        this.chosenFileTarget.classList.add("hidden")
        this.durationTarget.classList.remove("hidden")

        this.toggleRecordButtons()
        this.toggleDurationTracking()
        break;
      case "recorded":
        if (this.refreshInterval) {
          /* stop recording tracking if there is one running */
          this.durationTarget.classList.add("hidden")
          this.toggleDurationTracking()
        }

        break;
      default:
        break;
    }
  }

  /* displayBlob
    display video file in video html tag, clean the previous video element if there was one */
  private displayBlob(video: Blob | File): void {
    const oldVideoSrc: string = this.playerTarget.src
    const blobURL: string = URL.createObjectURL(video)

    if (oldVideoSrc && oldVideoSrc.startsWith('blob:')) {
      this.playerTarget.src = ''
      URL.revokeObjectURL(oldVideoSrc)
    }

    setSrcObject(null, this.playerTarget)

    this.playerTarget.src = blobURL
    this.playerTarget.poster = ""

    this.playerTarget.muted = false

    setTimeout(() => {
      this.dispatch("recorded", { video: video })
    }, 500);
  }

  /* displayChosenFile
    display a little text above save button to show the related file to save */
  private displayChosenFile(text: string = "default") {
    if (text === "default") {
      this.chosenFileTarget.innerHTML = this.chosenFileTarget.dataset.default
    } else {
      this.chosenFileTarget.innerHTML = `${this.chosenFileTarget.dataset.chosenFile} ${text}`
    }
    this.chosenFileTarget.classList.remove("hidden")
    this.hideNoVideoOverlay()
  }

  /* startRecording:
    start recording video using RecordRTC
  */
  private startRecording() {
    this.recorder = new RecordRTCPromisesHandler(this.stream, {
      type: "video",
      mimeType: "video/mp4",
      video: this.playerTarget
    })

    this.recorder.recordRTC.setRecordingDuration(2 * 60 * 1000).onRecordingStopped(() => {
      this.stopRecording(false)
    })

    this.recorder.startRecording().then(() => {
      this.stateValue = 'recording'
    }).catch((error) => {
      console.error(error)
    })
  }

  /* stopRecording:
    stop Recording using recordRTC */
  private async stopRecording(stoppedByUser: boolean = true) {
    try {
      /* Change state value, display hidden buttons */
      this.stateValue = 'recorded'

      /* stop recording of the record rtc recorder */
      if (stoppedByUser) {
        const stopRecordingResult: string = await this.recorder.stopRecording()
        console.debug(stopRecordingResult)
      }
      const video: Blob = await this.recorder.getBlob()
      const tracks: MediaStreamTrack[] = this.stream.getTracks()

      /* Stop stream */
      tracks.forEach((track: MediaStreamTrack) => track.stop())

      /* Save file locally (for debugging) */
      //this.recorder.recordRTC.save('video.webm')

      /* Display registered video in video tag */
      this.displayBlob(video)
      this.displayChosenFile()

      /* when stopping a recording, display hidden save button & info */
      this.toggleRecordButtons("show")

    } catch (error) {
      console.error(error)
    }
  }

  /* getDevice:
    get device to use to start recording */
  private async getDevice() {
    try {
      /* get Stream / recording device */
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { echoCancellation: true },
        video: {
          width: { min: 320, ideal: 1280, max: 2560 },
          height: { min: 240, ideal: 720, max: 1536 }
        }
      })

      /* Apply stream to the video tag */
      setSrcObject(stream, this.playerTarget)

      // Start to display the preview on the video element
      // and mute the video to disable the echo issue !
      this.playerTarget.play()
      this.playerTarget.muted = true
      this.playerTarget.controls = true

      this.stateValue = 'device-ready'
      this.stream = stream

      this.hideNoVideoOverlay()
    } catch (error) {
      console.error(error)
    }
  }
}
