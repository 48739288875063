import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {

  static targets = ['slider', 'currentSlide']

  declare readonly sliderTarget: HTMLDivElement
  declare readonly currentSlideTarget: HTMLDivElement

  connect() {
    this.sliderTarget.scrollLeft = this.currentSlideTarget.offsetLeft - this.sliderTarget.offsetLeft
  }

  disconnect() {
  }

}
