import Axios, { AxiosResponse } from 'axios'
import { ApplicationController } from 'stimulus-use'
import { Treeselect, ValueOptionType } from 'treeselectjs'
import { HierarchyTree, Tag, TagsData } from 'types/tags'
import { FrameElement } from '@hotwired/turbo/dist/types'

export default class extends ApplicationController {
  static targets = ['container', 'selectedTags', 'loader']

  static values = {
    loadUrl: String,
    setUrl: String
  }

  declare readonly containerTarget: HTMLDivElement
  declare readonly loaderTarget: HTMLDivElement
  declare readonly selectedTagsTarget: FrameElement

  declare loadUrlValue?: string
  declare setUrlValue?: string

  tagsSelectionHandler?: (event: InputEvent) => void
  treeSelects: Treeselect[] = []

  connect() {
    this.loadTree()
  }

  disconnect(): void {
    this.treeSelects.forEach((treeSelect: Treeselect) => {
      treeSelect.srcElement.removeEventListener('input', this.tagsSelectionHandler)
    })
  }

  private async loadTree(): Promise<void> {
    const axiosResponse: AxiosResponse<any, any> = await Axios.get(this.loadUrlValue)

    const data: TagsData = axiosResponse.data

    if (data.hierarchies === undefined) {
      this.loaderTarget.classList.add("hidden")
      return
    }

    // const slot = document.createElement('div')
    // slot.innerHTML='<a class="treeselect-demo__slot" href="">Click!</a>'
    data.hierarchies.forEach((hierarchy: HierarchyTree) => {
      const containerNode: HTMLDivElement = document.createElement("div")
      const labelNode: HTMLSpanElement = document.createElement("strong")
      const treeNode: HTMLDivElement = document.createElement("div")
      const selectedTags: number[] = hierarchy.selected_tags.map((tag: Tag) => tag.value)
      let treeSelect: Treeselect

      containerNode.classList.add("hierarchy")

      labelNode.textContent = hierarchy.label
      containerNode.append(labelNode)

      treeNode.classList.add(`tag-tree-${hierarchy.value}`)
      containerNode.append(treeNode)

      this.containerTarget.append(containerNode)

      treeSelect = new Treeselect({
        parentHtmlContainer: treeNode,
        value: selectedTags,
        options: hierarchy.children
      })

      treeSelect.srcElement.addEventListener('input',
        this.tagsSelectionHandler = (e: InputEvent) => {
          this.tagsSelected(e.detail as any)
        }
      )

      this.treeSelects.push(treeSelect)
    })


    this.loaderTarget.classList.add("hidden")
  }

  private async tagsSelected(tagIds: number[]) {
    let selectedTags: ValueOptionType[] = []
    const selectedTagsNode: FrameElement = document.getElementById("selected-tags-frame") as FrameElement

    this.treeSelects.forEach((treeSelect: Treeselect) => {
      selectedTags = selectedTags.concat(treeSelect.value)
    })
    console.table(selectedTags)
    await Axios.patch(this.setUrlValue, { tag_ids: selectedTags })

    if (selectedTagsNode) {
      selectedTagsNode.reload()
    }
  }

}