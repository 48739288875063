import Axios from 'axios'
import Uploader from 'src/uploader'
import { ApplicationController } from 'stimulus-use'
import { TurboSubmitEndEvent } from '@hotwired/turbo/dist/types'

export default class extends ApplicationController {
  static targets = ['recorder', 'form', 'unsavedVideoMessage']

  static values = {
    videoUrl: String
  }

  declare readonly recorderTarget: HTMLElement
  declare readonly formTarget: HTMLFormElement
  declare readonly unsavedVideoMessageTarget: HTMLDivElement
  declare readonly hasUnsavedVideoMessageTarget: boolean

  declare videoUrlValue?: string

  uploadVideoHandler?: (event: TurboSubmitEndEvent) => void
  recordedVideo?: Blob

  connect() {
    document.documentElement.addEventListener('turbo:submit-end',
      this.uploadVideoHandler = (event: TurboSubmitEndEvent) => this.saved(event)
    )

    this.recordedVideo = undefined
  }

  disconnect() {
    document.documentElement.removeEventListener('turbo:submit-end', this.uploadVideoHandler)
  }

  setVideo(event: CustomEvent): void {
    this.recordedVideo = event.detail.video
    // console.log(globalThis.translations)
    const saveNow: boolean = confirm(globalThis.translations.answer.record_alert)
    if (saveNow) {
      this.formTarget.requestSubmit()
    } else if (this.hasUnsavedVideoMessageTarget) {
      this.unsavedVideoMessageTarget.classList.remove("hidden")
    }
  }

  private async saved(event: TurboSubmitEndEvent): Promise<boolean> {

    if (this.recordedVideo) {
      try {
        /* get video info after answer is saved */
        const videoInfo: any = await Axios.get(this.videoUrlValue)

        const uploader = new Uploader(".progress-bar")

        const uploadResult = await uploader.upload({
          file: this.recordedVideo,
          url: videoInfo.data.video.pre_signed_url
        })

        if (this.hasUnsavedVideoMessageTarget) {
          this.unsavedVideoMessageTarget.classList.add("hidden")
        }

        this.recordedVideo = undefined // empty recorded video after upload is complete

        await Axios.patch(videoInfo.data.update_url, { request: "process_video" })


      } catch (error) {
        console.error(error)
      }
    }

    return true
  }
}
